import Vue from 'vue';
import VueI18n from 'vue-i18n';

import componentMessages from './messages/en-GB/components.yml';
import dashboardsMessages from './messages/en-GB/dashboards.yml';
import insightsMessages from './messages/en-GB/insights.yml';
import workflowsMessages from './messages/en-GB/workflows.yml';

const messages = { ...workflowsMessages, ...dashboardsMessages, ...insightsMessages, ...componentMessages };

Vue.use(VueI18n);

let i18n: VueI18n;

const defaultLocale = 'en-GB';
const loadedLanguages = ['en-GB'];

/**
 * This is the i18n instance that will be used throughout the application.
 *
 * @link https://phrase.com/blog/posts/ultimate-guide-to-vue-localization-with-vue-i18n/
 * @link https://kazupon.github.io/vue-i18n/guide/formatting.html
 */
function setup() {
    i18n = new VueI18n({
        fallbackLocale: defaultLocale,
        locale: defaultLocale,
        messages: { 'en-GB': messages },
    });
}

function setI18nLanguage(lang: string) {
    if (i18n.locale !== lang) {
        i18n.locale = lang;
        document.querySelector('html')?.setAttribute('lang', lang);
    }

    return lang;
}

const translationsInstance = {
    loadedLanguages: loadedLanguages,
    setLocale: setI18nLanguage,
    setup,
    get vueI18n() {
        return i18n;
    },
};

function loadLanguageAsync(lang: string) {
    // If the same language
    if (i18n.locale === lang) {
        return Promise.resolve(setI18nLanguage(lang));
    }

    // If the language was already loaded
    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang));
    }

    // If the language hasn't been loaded yet
    return import(`@/translations/messages/${lang}.json`).then((messages) => {
        i18n.setLocaleMessage(lang, messages);
        loadedLanguages.push(lang);

        return setI18nLanguage(lang);
    });
}

export { defaultLocale, translationsInstance as i18n, loadLanguageAsync };
