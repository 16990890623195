<template>
    <PrecisionApp :has-errors="appStore.hasErrors" :loading="appStore.isLoading" :valid-team="isValidTeam" />
</template>

<script setup lang="ts">
import axios from 'axios';
import pluck from 'lodash-es/map';
import { computed, nextTick, onBeforeMount, onMounted, provide, ref, watch } from 'vue';
import { useGetters } from 'vuex-composition-helpers';

import { useRoute } from '@/composables/useRoute';
import { useRouter } from '@/composables/useRouter';
import { useToaster } from '@/composables/useToaster';
import { IS_EMBEDDED } from '@/config/env';
import { DEXT_BASE_URL } from '@/config/urls';
import { PrepareReceiveMessageEvents, PrepareSendMessageEvents } from '@/enums/prepare/IframeMessageEvents';
import { useAppStore, useToastsStore } from '@/store/app';
import { useTeamsStore } from '@/store/teams';
import { loadLanguageAsync } from '@/translations/createTranslations';

import type { AppContextApi, TeamContextApi, UserContextApi, ClientContextApi } from './App.types';
import type { ReceiveIframeMessageEventData } from '@/schemas/app/IFrameEvents/ReceiveEvent.schema';
import type { TeamModel } from '@/schemas/Team.schema';
import type { Location } from 'vue-router';

import { AppContext } from './composables/useAppContext';
import { ClientContext } from './composables/useClientContext';
import { useIframeEventHandlers } from './composables/useIframeEventHandlers';
import { TeamContext } from './composables/useTeamContext';
import { UserContext } from './composables/useUserContext';
import PrecisionApp from './PrecisionApp.vue';

type Props = {
    teamRbExternalId?: string;
};

const props = defineProps<Props>();

/* Initialisation stores
 *
 * These stores are required for the application to run. If they are not booted
 * Then we should not render the application.
========================================================================== */
const appStore = useAppStore();
const teamsStore = useTeamsStore();
const toastsStore = useToastsStore();
const { currentUser } = useGetters('users', ['currentUser']);
const { currentClient } = useGetters('legacyClients', ['currentClient']);

const route = useRoute();
const router = useRouter();
const toaster = useToaster();

const prepareLocaleMap: Record<string, string> = {
    en: 'en-GB',
    fr: 'fr',
};
const locale = new URLSearchParams(window.location.search).get('locale') ?? 'en';
const localeMap = prepareLocaleMap[locale];

loadLanguageAsync(localeMap);

const isPrecisionEmbedded = ref(false);
const optInStatus = ref({ redirectUrl: window.location.href, status: 'show-precision' });
const updateOptInStatus = async (status: OptInStatus) => {
    optInStatus.value = status;
};

const { dispatchIframeEvent, hasIframeEventSubscriber, subscribeIframeEvent, unsubscribeIframeEvent } =
    useIframeEventHandlers();

const isValidTeam = computed(() => {
    if (teamsStore.currentTeam) {
        return true;
    }

    const teamIdsAsString = pluck(teamsStore.byId, 'rbExternalId').map((value) => value?.toString());

    return Boolean(props.teamRbExternalId && teamIdsAsString.includes(props.teamRbExternalId));
});

async function redirectToTeamHome() {
    // A whole bunch of state is being updated, we need to wait for it to all be
    // ready before we can redirect the user to the correct page.
    await nextTick();

    /**
     * @TODO: TEAM_CRN_IN_URL: Remove the cast when we don't need the
     *        legacy team store anymore.
     */
    const { rbExternalId } = teamsStore.currentTeam as TeamModel;

    router?.replace({
        name: 'team.home',
        params: { teamRbExternalId: rbExternalId.toString() },
    });
}

/**
 * @TODO: TEAM_CRN_IN_URL: Remove the cast when we don't need the
 *        legacy team store anymore.
 */
const currentTeam = computed(() => teamsStore.currentTeam as TeamModel);

provide<TeamContextApi>(TeamContext, {
    currentTeam,
    linkToPrepare(path: 'clients' | 'colleagues' | 'subscription', crn?: number) {
        const teamCrn = currentTeam.value.rbExternalId;

        return `${DEXT_BASE_URL}/deep-link/${path}?organisationCrn=${crn ?? teamCrn}`;
    },
});

provide<UserContextApi>(UserContext, {
    currentUser,
});

provide<ClientContextApi>(ClientContext, {
    currentClient,
});

provide<AppContextApi>(AppContext, {
    dispatchIframeEvent,
    hasIframeEventSubscriber,
    isEmbedded: isPrecisionEmbedded,
    optInStatus,
    subscribeIframeEvent,
    unsubscribeIframeEvent,
    updateOptInStatus,
});

(async () => {
    // Fetch the opt-in status for the user in their current team.
    // This is used elsewhere to determine if the homepage link should be shown in the app switcher
    // and if the user profile menu should sho the opt in link or the switch to the new experience link
    const response = await axios.get(`/api/teams/${props.teamRbExternalId}/opt-in-status`);

    updateOptInStatus({
        redirectUrl: response.data.redirect_url,
        status: response.data.status,
    });
})();

toastsStore.$subscribe((_mutation, state) => {
    const nextToast = state.toasts[0];

    if (!toaster || !nextToast) return;

    toastsStore.removeToast(nextToast.id);
    toaster[nextToast.type](nextToast.message, {
        timeout: nextToast.timeout ?? 8000,
    });
});

watch(
    () => appStore.isInitialised,
    (newValue) => {
        if (newValue && !props.teamRbExternalId) {
            redirectToTeamHome();
        }
    },
    { immediate: true }
);

watch(
    () => route.value?.name,
    (newValue) => {
        if (newValue === 'app.home') {
            redirectToTeamHome();
        }
    }
);

function onNavigateEventFromPrepare(data: {
    action: typeof PrepareReceiveMessageEvents.NAVIGATE;
    payload: Extract<ReceiveIframeMessageEventData, { action: typeof PrepareReceiveMessageEvents.NAVIGATE }>['payload'];
}) {
    /**
     * The embedded route is the default route for precision-in-prepare. If the
     * current route is the embedded route we will need to replace it, since it
     * doesn't actually exist and should not be accessed by the user.
     */
    const isCurrentRouteEmbedded = router?.currentRoute.name === 'app.embedded';

    const location: Location = {
        ...data.payload,
        /* @ts-expect-error We may have query here from the transform */
        query: { ...(data.payload.query ?? {}), isPrepareRedirect: 'true' },
    };

    isCurrentRouteEmbedded ? router?.replace(location) : router?.push(location);
}

function onUserPreferenceChange(data: { action: any; payload: { language: string } }) {
    appStore.setUserPreferences(data.payload);
}

onBeforeMount(() => {
    appStore.init();

    subscribeIframeEvent({
        action: PrepareReceiveMessageEvents.PROVIDE_USER_LANGUAGE,
        callback: onUserPreferenceChange,
        once: true,
    });

    subscribeIframeEvent({
        action: PrepareReceiveMessageEvents.NAVIGATE,
        callback: onNavigateEventFromPrepare,
        once: true,
    });
});

onMounted(() => {
    isPrecisionEmbedded.value = IS_EMBEDDED;
    dispatchIframeEvent({ action: PrepareSendMessageEvents.GET_USER_LANGUAGE });
});
</script>
