import { onBeforeMount, onBeforeUnmount, ref } from 'vue';

import { IS_STANDALONE } from '@/config/env';
import { DEXT_BASE_URL } from '@/config/urls';
import {
    ReceiveIfameMessageEventSchema,
    type ReceiveIframeMessageEventData,
} from '@/schemas/app/IFrameEvents/ReceiveEvent.schema';
import { SendIframeMessageEventSchema } from '@/schemas/app/IFrameEvents/SendEvent.schema';
import logger from '@/utils/logger';

import type { IFrameEventSubscriberParams } from '../App.types';
import type { PrepareReceiveMessageEvent } from '@/enums/prepare/IframeMessageEvents';
import type { SendIframeMessageEventData } from '@/schemas/app/IFrameEvents/SendEvent.schema';

type MessageEventSubscriberCallback<
    TActionName extends PrepareReceiveMessageEvent,
    TData extends ReceiveIframeMessageEventData,
> = (
    data: { action: TActionName } & (TData extends { action: TActionName; payload: infer TPayload }
        ? { payload: TPayload }
        : never)
) => void;

type MessageEventSubscriber<
    TActionName extends PrepareReceiveMessageEvent = PrepareReceiveMessageEvent,
    TData extends ReceiveIframeMessageEventData = ReceiveIframeMessageEventData,
> = {
    action: TActionName;
    callback: MessageEventSubscriberCallback<TActionName, TData>;
};

export function dispatchIframeEvent(data: SendIframeMessageEventData) {
    if (IS_STANDALONE) return;

    try {
        SendIframeMessageEventSchema.parse(data);
        window.parent.postMessage(data, '*');
    } catch (error) {
        logger.error(`[iframe]: Failed sending iframe event with action "${data.action}"`);

        console.log(error);
    }
}

export function useIframeEventHandlers() {
    const messageEventSubscribers = ref<MessageEventSubscriber[]>([]);

    onBeforeMount(() => {
        window.addEventListener('message', handleIframeEvent);
    });

    onBeforeUnmount(() => {
        window.removeEventListener('message', handleIframeEvent);
    });

    function subscribeIframeEvent<TAction extends PrepareReceiveMessageEvent = PrepareReceiveMessageEvent>(
        params: MessageEventSubscriber<TAction> & { once?: boolean }
    ) {
        if (
            params.once &&
            hasIframeEventSubscriber({
                action: params.action,
                callback: params.callback,
            })
        ) {
            return;
        }

        messageEventSubscribers.value = [
            ...messageEventSubscribers.value,
            { action: params.action, callback: params.callback },
        ];
    }

    function unsubscribeIframeEvent(params: IFrameEventSubscriberParams) {
        messageEventSubscribers.value = messageEventSubscribers.value.filter(
            (subscriber) => subscriber.action !== params.action && subscriber.callback !== params.callback
        );
    }

    function hasIframeEventSubscriber<TAction extends PrepareReceiveMessageEvent>(
        params: MessageEventSubscriber<TAction>
    ) {
        return messageEventSubscribers.value.some(
            (subscriber) => subscriber.action === params.action && subscriber.callback === params.callback
        );
    }

    function hasPayload(data: { action: string; payload?: unknown }): data is { action: string; payload: unknown } {
        return data.payload !== undefined;
    }

    function handleIframeEvent(event: MessageEvent) {
        if (event.origin !== DEXT_BASE_URL) return;

        console.log('debug handler', event.data);

        const parsedData = ReceiveIfameMessageEventSchema.parse(event.data);

        messageEventSubscribers.value.forEach((subscriber) => {
            if (subscriber.action !== parsedData.action) return;

            if (hasPayload(parsedData)) {
                return subscriber.callback({
                    action: parsedData.action,
                    payload: parsedData.payload,
                });
            }

            subscriber.callback({ action: parsedData.action });
        });
    }

    return {
        dispatchIframeEvent,
        hasIframeEventSubscriber,
        subscribeIframeEvent,
        unsubscribeIframeEvent,
    };
}
