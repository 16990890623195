<template>
    <SidebarGroup name="Client Insights">
        <template #activator>Business Insights</template>

        <template #icon-left>
            <VIcon decorative name="business-insights-thick" />
        </template>

        <SidebarItem v-for="item in items" :key="item.name" :name="item.name" sub-item :to="item.to">
            {{ item.label }}
        </SidebarItem>
    </SidebarGroup>
</template>

<script>
import PropTypes from 'vue-types';

import { SidebarGroup, SidebarItem } from '@/layout/Sidebar';

import useBaseGroup from '../hooks/useBaseGroup';

export default {
    name: 'ClientSidebarGroupInsights',

    components: { SidebarGroup, SidebarItem },

    props: {
        client: PropTypes.object.isRequired,
        reportMode: PropTypes.string.isRequired,
    },

    setup(props) {
        const { createItems, isInsightSupported } = useBaseGroup(props.client, props.reportMode);

        const isSoleTraderOrPartnership =
            props.client.organisationType === 'SOLE_TRADER' || props.client.organisationType === 'PARTNERSHIP';

        const supportsCorpTax = props.client.supportsCorpTax && !isSoleTraderOrPartnership;

        return {
            createItems,
            isInsightSupported,
            supportsCorpTax,
        };
    },

    computed: {
        items() {
            return this.createItems([
                this.isInsightSupported('aged-balances') && {
                    label: 'Aged Balances',
                    name: 'Aged Balances',
                    to: { name: 'client.insight.agedBalances' },
                },
                this.isInsightSupported('go-proposal') && {
                    label: 'Activity Stats',
                    name: 'Activity Stats',
                    to: { name: 'client.insight.activityStats' },
                },
                this.client.countryCode === 'GB' && {
                    label: !this.isIaUpdateEnabled ? 'Benchmarking' : 'Performance Benchmarking',
                    name: 'Benchmarking',
                    to: { name: 'client.benchmarking' },
                },
                this.isInsightSupported('regular-suppliers', 'cost-by-account-code') && {
                    label: 'Cost Analysis',
                    name: 'Cost Analysis',
                    to: { name: 'client.insight.costAnalysis' },
                },
                this.isInsightSupported('sales-analysis') && {
                    label: 'Sales Analysis',
                    name: 'Sales Analysis',
                    to: { name: 'client.insight.salesAnalysis', query: { mode: 'dashboard' } },
                },
                this.isInsightSupported('business-performance') && {
                    label: 'Metrics',
                    name: 'Metrics',
                    to: { name: 'client.resilience.businessPerformance' },
                },
            ]);
        },
    },
};
</script>

<style lang="scss" scoped></style>
