<template>
    <SidebarGroup ref="focusSidebarGroupRef" name="Focus">
        <template #activator>Workflows</template>

        <template #icon-left="{ isActive }">
            <VIcon decorative :name="isActive ? 'workflows-filled' : 'workflows-thick'" />
        </template>

        <SidebarItem v-if="!teamIsBase" name="Flow Creator" sub-item :to="{ name: 'team.focus.flowCreator' }">
            Workflow Creator
        </SidebarItem>

        <SidebarItem
            v-if="!teamIsBase"
            ref="workflowListSidebarItemRef"
            exact
            name="Flow List"
            sub-item
            :to="{ name: 'team.focus' }"
        >
            Workflow List
        </SidebarItem>

        <SidebarItem
            v-if="canCreateFlowTemplates"
            name="Flow Templates"
            sub-item
            :to="{ name: 'team.focus.flowTemplates' }"
        >
            Workflow Templates
        </SidebarItem>

        <SidebarItem
            v-if="canCreateFlowTemplates"
            name="Custom Checks"
            sub-item
            :to="{ name: 'team.focus.customChecks' }"
        >
            Custom Checks
        </SidebarItem>

        <SidebarItem v-if="!teamIsBase" name="Repeating Flows" sub-item :to="{ name: 'team.focus.repeatingFlows' }">
            Repeating Workflows
        </SidebarItem>
    </SidebarGroup>
</template>

<script>
import { useProductTourTemplateRefs } from '@/composables/tours/useProductTourTemplateRefs';
import UserPermissions from '@/enums/UserPermissions';
import useXavierGlobals from '@/hooks/useXavierGlobals';
import { SidebarGroup, SidebarItem } from '@/layout/Sidebar';
import { hasPermission } from '@/utils/permissions';

export default {
    name: 'TeamSidebarGroupFocus',

    components: { SidebarGroup, SidebarItem },

    setup() {
        const { workflowListSidebarItemRef, focusSidebarGroupRef } = useProductTourTemplateRefs();

        const canCreateFlowTemplates = hasPermission(UserPermissions.CREATE_FLOW_TEMPLATES);
        const { teamIsBase } = useXavierGlobals();

        return {
            canCreateFlowTemplates,
            focusSidebarGroupRef,
            teamIsBase,
            workflowListSidebarItemRef,
        };
    },
};
</script>
