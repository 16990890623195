import type { RouteConfig } from 'vue-router';

import { extractClientIdParam } from '../utils';

const insightsRoutes: RouteConfig[] = [
    {
        component: () => import('@/scenes/Insights/SalesAnalysis/SalesAnalysisSceneContainer.vue'),
        meta: { disableLegacyStyles: true },
        name: 'client.insights.salesAnalysis',
        path: 'insights/sales-analysis',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/Insights/CostAnalysis/CostAnalysisSceneContainer.vue'),
        meta: { disableLegacyStyles: true },
        name: 'client.insights.costAnalysis',
        path: 'insights/cost-analysis',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/Insights/ActivityStats/ActivityStatsSceneContainer.vue'),
        meta: { disableLegacyStyles: true },
        name: 'client.insights.activityStats',
        path: 'insights/activity-stats',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/Insights/AgedBalances/AgedBalancesSceneContainer.vue'),
        meta: { disableLegacyStyles: true },
        name: 'client.insights.agedBalances',
        path: 'insights/aged-balances',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/Insights/BusinessPerformance/BusinessPerformanceSceneContainer.vue'),
        meta: { disableLegacyStyles: true },
        name: 'client.insights.metrics',
        path: 'insights/metrics',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/Insights/PerformanceBenchmarking/PerformanceBenchmarkingSceneContainer.vue'),
        meta: { disableLegacyStyles: true },
        name: 'client.insights.performanceBenchmarking',
        path: 'insights/benchmarking',
        props: extractClientIdParam,
    },
    // Custom Reports
    {
        component: () => import('@/scenes/Sandbox/SandboxScene.vue'),
        meta: { disableLegacyStyles: true },
        name: 'client.sandbox.unified',
        path: 'insights/sandbox',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/PracticeDashboards/CustomDashboards/CustomDashboardsSceneContainer.vue'),
        meta: { disableLegacyStyles: true },
        name: 'client.reports.unified',
        path: 'insights/client-dashboards',
        props: extractClientIdParam,
    },
];

export { insightsRoutes };
