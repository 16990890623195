<template>
    <SidebarGroup name="Client Cleanup">
        <template #activator>Cleanup</template>

        <template #icon-left="{ isActive }">
            <VIcon decorative :name="isActive ? 'clean-up-filled' : 'clean-up-thick'" />
        </template>

        <SidebarItem v-for="item in items" :key="item.name" :name="item.name" sub-item :to="item.to">
            {{ item.label }}
        </SidebarItem>
    </SidebarGroup>
</template>

<script>
import { defineComponent } from 'vue';
import * as PropTypes from 'vue-types';

import { ClientProvider } from '@/enums/ClientProvider';
import countryCodes from '@/enums/CountryCodes';
import { Feature } from '@/enums/Feature';
import { SidebarGroup, SidebarItem } from '@/layout/Sidebar';
import useIsFeatureEnabled from '@/store/modules/feature/hooks/useIsFeatureEnabled';

import useBaseGroup from '../hooks/useBaseGroup';

export default defineComponent({
    name: 'ClientSidebarGroupCleanup',

    components: { SidebarGroup, SidebarItem },

    props: {
        client: PropTypes.object().isRequired,
        reportMode: PropTypes.string().isRequired,
    },

    setup(props) {
        const abnCountryCodes = [countryCodes.AUSTRALIA];
        const clientSupportsAbn = abnCountryCodes.includes(props.client.countryCode);
        const { createItems, isInsightSupported } = useBaseGroup(props.client, props.reportMode);

        const duplicateTransactionsEnabled = useIsFeatureEnabled(Feature.DUPLICATE_TRANSACTIONS);

        const transactionCleanupEnabled =
            props.client.provider === ClientProvider.QUICKBOOKS &&
            useIsFeatureEnabled(Feature.TRANSACTION_CLEANUP) &&
            useIsFeatureEnabled(Feature.TRANSACTION_CLEANUP_FRONTEND);

        const abnValidationEnabled = clientSupportsAbn && useIsFeatureEnabled(Feature.ABN_VALIDATION);

        return {
            abnValidationEnabled,
            createItems,
            duplicateTransactionsEnabled,

            isInsightSupported,
            transactionCleanupEnabled,
        };
    },

    computed: {
        items() {
            return this.createItems([
                this.client.provider === 'xero' &&
                    this.isInsightSupported('sales-analysis') && {
                        label: 'Bank Reconciliation',
                        name: 'Bank Reconciliation',
                        to: { name: 'client.cleanup.bankReconciliation' },
                    },
                this.client.provider === 'quickbooks' &&
                    this.isInsightSupported('unreconciled-transactions') && {
                        label: 'Unreconciled Transactions',
                        name: 'Unreconciled Transactions',
                        to: { name: 'client.cleanup.unreconciledTransactions' },
                    },
                this.isInsightSupported('duplicate-contacts') && {
                    label: 'Duplicate Contacts',
                    name: 'Duplicate Contacts',
                    to: { name: 'client.cleanup.duplicateContacts.legacy' },
                },
                this.isInsightSupported('multi-coded') && {
                    label: 'Multi-Coded Contacts',
                    name: 'Multi-Coded Contacts',
                    to: { name: 'client.cleanup.multi-codedContacts' },
                },
                this.isInsightSupported('contact-defaults') && {
                    label: 'Contact Defaults',
                    name: 'Contact Defaults',
                    to: { name: 'client.cleanup.contactDefaults' },
                },

                this.isInsightSupported('dormant-accounts') && {
                    label: 'Dormant Accounts',
                    name: 'Dormant Accounts',
                    to: { name: 'client.cleanup.dormantAccounts' },
                },
                this.duplicateTransactionsEnabled &&
                    this.isInsightSupported('duplicate-transactions') && {
                        label: 'Duplicate Transactions',
                        name: 'Duplicate Transactions',
                        to: { name: 'client.cleanup.duplicateTransactions' },
                    },
                this.transactionCleanupEnabled &&
                    this.isInsightSupported('transaction-cleanup') && {
                        label: 'Transaction Cleanup',
                        name: 'Transaction Cleanup',
                        to: { name: 'client.cleanup.transactionCleanup' },
                    },
                this.isInsightSupported('fixed-assets') && {
                    label: 'Fixed Assets',
                    name: 'Fixed Assets',
                    to: { name: 'client.insight.fixedAssets' },
                },
                this.isInsightSupported('control-accounts') && {
                    label: 'Control Accounts',
                    name: 'Control Accounts',
                    to: { name: 'client.insight.controlAccounts' },
                },
                this.client.countryCode === countryCodes.AUSTRALIA &&
                    this.client.provider === ClientProvider.XERO &&
                    this.client.payrollSupported && {
                        label: 'Payroll',
                        name: 'Payroll',
                        to: { name: 'client.insight.payroll' },
                    },
            ]);
        },
    },
});
</script>
