import type { RouteConfig } from 'vue-router';

import { extractClientIdParam } from '../utils';

const insightRoutes: RouteConfig[] = [
    {
        component: () => import('@/scenes/HistoricalChanges_LEGACY/HistoricalChangesScene.vue'),
        name: 'client.insight.historicalChanges',
        path: 'insight/historical-changes',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/LockDateHistory_LEGACY/LockDateHistoryScene.vue'),
        name: 'client.insight.lockDates',
        path: 'insight/lock-dates',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/CorporationTax_LEGACY/CorporationTaxScene.vue'),
        name: 'client.insight.corporationTax',
        path: 'insight/corporation-tax',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/FixedAssets_LEGACY/FixedAssetsScene.vue'),
        name: 'client.insight.fixedAssets',
        path: 'insight/fixed-assets',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/AgedBalances_LEGACY/AgedBalancesScene.vue'),
        name: 'client.insight.agedBalances',
        path: 'insight/aged-balances',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/ControlAccounts_LEGACY/ControlAccountsScene.vue'),
        name: 'client.insight.controlAccounts',
        path: 'insight/control-accounts',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/CostAnalysis_LEGACY/CostAnalysisScene.vue'),
        name: 'client.insight.costAnalysis',
        path: 'insight/cost-analysis',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/ActivityStats_LEGACY/ActivityStatsScene.vue'),
        name: 'client.insight.activityStats',
        path: 'insight/activity-stats',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/SalesAnalysis_LEGACY/SalesAnalysisScene.vue'),
        name: 'client.insight.salesAnalysis',
        path: 'insight/sales-analysis',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/Payroll_LEGACY'),
        name: 'client.insight.payroll',
        path: 'insight/payroll',
        props: extractClientIdParam,
    },
];

export { insightRoutes };
