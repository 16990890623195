/* eslint-disable sort-keys */

/**
 * WorkflowCreatorStage enum.
 *
 * DO NOT CHANGE THIS ORDER. The order is important for the workflow creator.
 */
export const WorkflowCreatorStage = {
    CHECKS: 'checks',
    DATES: 'dates',
    PARAMETERS: 'parameters',
    CLIENTS: 'clients',
    REVIEW: 'review',
} as const;

export type WorkflowCreatorStageKey = keyof typeof WorkflowCreatorStage;
export type WorkflowCreatorStage = (typeof WorkflowCreatorStage)[WorkflowCreatorStageKey];
