import type { RouteConfig } from 'vue-router';

import { extractClientIdParam } from '../utils';

const resilienceRoutes: RouteConfig[] = [
    {
        path: 'resilience/business-performance',
        component: () => import('@/scenes/BusinessPerformance_LEGACY/BusinessPerformanceScene.vue'),
        props: extractClientIdParam,
        name: 'client.resilience.businessPerformance',
    },
];

export { resilienceRoutes };
