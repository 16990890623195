import { z } from 'zod';

import { PrepareSendMessageEvents } from '@/enums/prepare/IframeMessageEvents';

import { SendNavigatePayloadSchema } from './SendNavigateEvent.schema';

export const SendIframeMessageEventSchema = z.union([
    z.object({
        action: z.enum([
            PrepareSendMessageEvents.GET_OFFSET,
            PrepareSendMessageEvents.GET_USER_LANGUAGE,
            PrepareSendMessageEvents.HIDE_OVERLAY,
            PrepareSendMessageEvents.SHOW_OVERLAY,
        ]),
    }),

    z.object({
        action: z.literal(PrepareSendMessageEvents.NAVIGATE),
        payload: SendNavigatePayloadSchema,
    }),
    z.object({
        action: z.literal(PrepareSendMessageEvents.SHOW_TOASTER),
        payload: z.object({}),
    }),
]);

export type SendIframeMessageEventData = z.infer<typeof SendIframeMessageEventSchema>;
