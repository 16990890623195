<template>
    <div class="disconnected-client text-left">
        <VButton v-if="subscriptionValid" link name="Go to Client" text @click="goToClientOverview()">
            {{ params.data.name }}
        </VButton>

        <span v-else class="text-primary">
            {{ params.data ? params.data.name : '' }}
        </span>

        <DBadge variant="error">Disconnected</DBadge>
    </div>
</template>

<script>
import Vue from 'vue';

import { dispatchIframeEvent } from '@/App/composables/useIframeEventHandlers';
import { DBadge } from '@/components/Badge';
import { ClientNavigationPathNames } from '@/enums/prepare/IFrameNavigationPathNames';
import useXavierGlobals from '@/hooks/useXavierGlobals';

export default Vue.extend({
    name: 'DisconnectedClientRenderer',
    components: { DBadge },
    props: {
        params: {
            type: Object,
            required: true,
        },
    },
    setup() {
        const { subscriptionValid } = useXavierGlobals();

        return { subscriptionValid };
    },

    methods: {
        goToClientOverview() {
            const { currentTeam } = useXavierGlobals();

            dispatchIframeEvent({
                action: 'NAVIGATE',
                payload: {
                    pathName: ClientNavigationPathNames.CLIENT_OVERVIEW,

                    clientCrn: this.params.data.slug,
                    practiceCrn: currentTeam.rbExternalId.toString(),
                },
            });

            this.$router.push({ name: 'client.overview', params: { clientSlug: this.params.data.slug } });
        },
    },
});
</script>

<style lang="scss">
.disconnected-client {
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
}

.d-badge {
    margin-left: auto;
}
</style>
