import type { RouteConfig } from 'vue-router';

import { extractClientIdParam } from '../utils';

const resilienceRoutes: RouteConfig[] = [
    {
        component: () => import('@/scenes/BusinessPerformance_LEGACY/BusinessPerformanceScene.vue'),
        name: 'client.resilience.businessPerformance',
        path: 'resilience/business-performance',
        props: extractClientIdParam,
    },
];

export { resilienceRoutes };
