<template>
    <div>
        <span class="secondary-nav-link nav-link-group">
            <VIcon name="filter-thick" />
        </span>
        <a
            class="secondary-nav-link nav-link-group pr-0 nowrap"
            :class="{ active: viewClients === 'all' }"
            @click="switchView('all')"
        >
            All Clients <span class="badge badge-pill filter-badge">{{ allClientsCount }}</span>
        </a>
        <span class="secondary-nav-link pl-1 pr-1">|</span>
        <a
            class="secondary-nav-link nav-link-group pr-0 pl-0 nowrap"
            :class="{ active: viewClients === 'myClients' }"
            @click="switchView('myClients')"
        >
            My Clients <span class="badge badge-pill filter-badge">{{ myClientsCount }}</span>
        </a>
        <span class="secondary-nav-link pl-1 pr-1" v-if="favorites">|</span>
        <a
            v-if="favorites"
            class="secondary-nav-link nav-link-group pl-0 nowrap"
            :class="{ active: viewClients === 'favourites' }"
            @click="switchView('favourites')"
        >
            Favourites <span class="badge badge-pill filter-badge">{{ favouritesCount }}</span>
        </a>

        <span class="secondary-nav-link nav-link-group ml-3" v-if="sortOrder">
            <VIcon :name="sortIcon" />
        </span>
        <a
            class="secondary-nav-link nav-link-group pr-0 nowrap"
            :class="{ active: sortOrder === 'asc' }"
            @click="$emit('switchOrder', 'asc')"
            v-if="sortOrder"
        >
            Asc
        </a>
        <span class="secondary-nav-link pl-1 pr-1" v-if="sortOrder">|</span>
        <a
            class="secondary-nav-link nav-link-group pr-0 pl-0 nowrap"
            :class="{ active: sortOrder === 'desc' }"
            @click="$emit('switchOrder', 'desc')"
            v-if="sortOrder"
        >
            Desc
        </a>
        <slot />
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import * as PropTypes from 'vue-types';

import { getCurrentUserClients, getFavouritedClients } from '@/utils/clients';
import { computed } from 'vue';

const { mapGetters } = createNamespacedHelpers('legacyClients');

export default {
    name: 'ClientListFilter',
    props: {
        clientList: PropTypes.arrayOf(PropTypes.object()).isRequired,
        sortOrder: PropTypes.string(),
        value: PropTypes.string().def('all'),
        favorites: PropTypes.bool().def(true),
    },

    data() {
        return {
            viewClients: this.value,
        };
    },

    setup(props) {
        return {
            allClientsCount: computed(() => props.clientList.length),
            myClientsCount: computed(() => getCurrentUserClients(props.clientList).length),
            favouritesCount: computed(() => getFavouritedClients(props.clientList).length),
            sortIcon: computed(() => (props.sortOrder === 'desc' ? 'sort-amount-down-thick' : 'sort-amount-up-thick')),
        };
    },

    async created() {
        this.viewClients = this.$settings.get('viewClients') || 'all';
    },
    methods: {
        switchView(newView) {
            this.viewClients = newView;
            this.$settings.set('viewClients', newView);

            this.$emit('input', newView);
        },
    },
};
</script>

<style lang="scss" scoped>
.secondary-nav {
    .filter-badge {
        padding-top: 5px;
    }
}
</style>
