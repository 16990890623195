import type { RouteConfig } from 'vue-router';

import { extractClientIdParam } from '../utils';

const customReportRoutes: RouteConfig[] = [
    {
        component: () => import('@/scenes/Sandbox_LEGACY/SandboxScene.vue'),
        name: 'client.customReport.details',
        path: 'custom-report/:reportId',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/Sandbox/SandboxScene.vue'),
        meta: { disableLegacyStyles: true },
        name: 'client.customReport.details.unified',
        path: 'custom-report-unified/:reportId',
        props: extractClientIdParam,
    },
];

export { customReportRoutes };
