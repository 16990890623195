const INJECT_CSS = `
body {
    overflow: hidden;
}

.bean {
    transform: translate3d(0, 0, 0);
    cursor: pointer;
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23ff7c6e' d='M261.332 0c96.855 0 170.306 87.444 153.71 182.993l-8.031 46.239c-3.076 17.712-3.076 35.823 0 53.535l8.031 46.239C431.637 424.556 358.186 512 261.333 512c-73.394 0-136.865-51.223-152.457-123.038-19.022-87.618-19.022-178.305 0-265.923C124.466 51.223 187.937 0 261.332 0z' /%3E%3Cpath fill='%23ff6554' d='M297.587 507.773c-11.599 2.754-23.728 4.227-36.254 4.227-73.394 0-136.865-51.223-152.457-123.038-19.022-87.618-19.022-178.305 0-265.923C124.466 51.223 187.937 0 261.332 0c12.526 0 24.655 1.474 36.254 4.227-57.341 13.637-103.401 59.105-116.363 118.811-19.022 87.618-19.022 178.305 0 265.923 12.963 59.707 59.023 105.175 116.364 118.812z' /%3E%3Cpath fill='%23ffa69f' d='M333.913 72.348c0 15.368-12.458 27.826-27.826 27.826s-27.826-12.458-27.826-27.826 12.458-27.826 27.826-27.826 27.826 12.458 27.826 27.826zm16.696 38.956c-9.22 0-16.696 7.475-16.696 16.696s7.475 16.696 16.696 16.696c9.22 0 16.696-7.475 16.696-16.696s-7.476-16.696-16.696-16.696z' /%3E%3C/svg%3E");
    transform-origin: center;
    transform: translate(-5000px, -5000px);
    width: 50px;
    height: 50px;
}

.bean-count {
    font-family: sans-serif;
    font-size: 30px;
    text-align: right;
    position: absolute;
    right: 20px;
    bottom: 10px;
}
`;

const GRAVITY = 0.2;
const MAX_ACCEL = -3;
const BEAN_INTERVAL = 400;
const FRAME_INTERVAL = 1000 / 80;
const BEAN_SIZE = 50;

function goBeans(el = '#spark-app') {
    const beans = [];
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    let running = true;
    let count = 0;

    const styleElement = document.createElement('style');

    styleElement.innerHTML = INJECT_CSS;

    document.head.appendChild(styleElement);

    const countElement = document.createElement('p');

    countElement.className = 'bean-count';

    document.body.appendChild(countElement);

    const stage = document.querySelector('#spark-app');

    function emitBean() {
        const bean = document.createElement('div');

        bean.style.transform = `translate(${screenWidth / 2}px, ${screenHeight}px)`;
        bean.style.filter = `hue-rotate(${Math.random() * 360}deg)`;
        bean.classList = ['bean'];
        bean.addEventListener('click', beanCounted);

        stage.appendChild(bean);

        beans.push({
            el: bean,
            x: screenWidth / 2,
            y: screenHeight,
            velX: Math.random() * 6 - 3,
            velY: Math.random() * -5 - 22,
            rotation: 0,
            velRotation: Math.random() * 10 + 5,
        });
    }

    function updateBeans() {
        for (let i = beans.length - 1; i >= 0; i--) {
            const bean = beans[i];

            bean.velY += Math.max(GRAVITY, MAX_ACCEL);

            bean.rotation = (bean.rotation + bean.velRotation) % 360;
            bean.x += bean.velX;
            bean.y += bean.velY;

            bean.el.style.transform = `translate(${bean.x}px, ${bean.y}px) rotate(${bean.rotation}deg)`;

            if (bean.x < 0 || bean.x > screenWidth || bean.y > screenHeight + BEAN_SIZE) {
                beans.splice(i, 1);
                stage.removeChild(bean.el);
            }
        }
    }

    function beanCounted({ srcElement }) {
        for (let i = beans.length - 1; i >= 0; i--) {
            const bean = beans[i];

            if (bean.el === srcElement) {
                beans.splice(i, 1);
                stage.removeChild(bean.el);
            }
        }

        countElement.innerText = `${++count} bean${count > 1 ? 's' : ''} counted`;
    }

    let lastFrame = performance.now();
    let lastBean = performance.now();

    function frame() {
        if (!running) {
            return;
        }

        requestAnimationFrame(frame);

        const now = performance.now();

        if (now - lastBean > BEAN_INTERVAL) {
            lastBean = performance.now();
            emitBean();
        }

        if (now - lastFrame < FRAME_INTERVAL) {
            return;
        }

        lastFrame = now;

        updateBeans();
    }

    function stop() {
        running = false;

        for (let i = beans.length - 1; i >= 0; i--) {
            stage.removeChild(beans[i].el);
            beans.splice(i, 1);
        }

        document.head.removeChild(styleElement);
        document.body.removeChild(countElement);
    }

    frame();

    return stop;
}

window.Xavier = window.Xavier ?? {};
window.Xavier.goBeans = goBeans;
