import type { RouteConfig } from 'vue-router';

const customReportsRoutesUnified: RouteConfig[] = [
    {
        component: () => import('@/scenes/PracticeDashboards/Practice/PracticeSceneContainer.vue'),
        meta: { disableLegacyStyles: true },
        name: 'team.customReport.dashboard.details.unified',
        path: 'practice-dashboards/custom-dashboards/practice/:reportId',
        props: true,
    },
    {
        component: () => import('@/scenes/PracticeDashboards/ActivityStats/ActivityStatsSceneContainer.vue'),
        meta: { disableLegacyStyles: true },
        name: 'team.customReport.activity.details.unified',
        path: 'practice-dashboards/custom-dashboards/activity/:reportId',
        props: true,
    },
    {
        component: () => import('@/scenes/PracticeDashboards/Mtd/MtdSceneContainer.vue'),
        meta: { disableLegacyStyles: true },
        name: 'team.customReport.mtd.details.unified',
        path: 'practice-dashboards/custom-dashboards/mtd/:reportId',
        props: true,
    },
    {
        component: () => import('@/scenes/PracticeDashboards/Workflows/WorkflowsSceneContainer.vue'),
        meta: { disableLegacyStyles: true },
        name: 'team.customReport.focus.details.unified',
        path: 'practice-dashboards/custom-dashboards/focus/:reportId',
        props: true,
    },
    {
        component: () => import('@/scenes/PracticeDashboards/Deadlines/DeadlinesSceneContainer.vue'),
        meta: { disableLegacyStyles: true },
        name: 'team.customReport.deadlines.details.unified',
        path: 'practice-dashboards/custom-dashboards/deadlines/:reportId',
        props: true,
    },
];

export { customReportsRoutesUnified };
