import type { RouteConfig } from 'vue-router';

import { extractClientIdParam } from '../utils';

const checkRoutes: RouteConfig[] = [
    {
        component: () => import('@/scenes/VATReturn_LEGACY/VatReturnScene.vue'),
        name: 'client.check.vatReturn',
        path: 'check/vat-return',
        props: extractClientIdParam,
    },
    {
        component: () =>
            import('@/scenes/EntertainmentAccountsCheck_LEGACY').then((res) => res.EntertainmentAccountsCheck),
        name: 'client.check.entertainmentAccounts',
        path: 'check/entertainment-accounts',
        props: extractClientIdParam,
    },
];

export { checkRoutes };
