import { z } from 'zod';

import { PrepareReceiveMessageEvents } from '@/enums/prepare/IframeMessageEvents';

import { ReceiveNavigatePayloadSchema } from './ReceiveNavigateEvent.schema';

export const ReceiveIfameMessageEventSchema = z.union([
    z.object({ action: z.literal(PrepareReceiveMessageEvents.OVERLAY_CLICKED) }),
    z.object({
        action: z.literal(PrepareReceiveMessageEvents.NAVIGATE),
        payload: ReceiveNavigatePayloadSchema,
    }),
    z.object({
        action: z.literal(PrepareReceiveMessageEvents.PROVIDE_OFFSET),
        payload: z.object({ left: z.number(), top: z.number() }),
    }),
    z.object({
        action: z.literal(PrepareReceiveMessageEvents.PROVIDE_USER_LANGUAGE),
        payload: z.object({ language: z.string() }),
    }),
]);

export type ReceiveIframeMessageEventData = z.infer<typeof ReceiveIfameMessageEventSchema>;
