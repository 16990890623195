<template>
    <div class="dext-account-switcher-wrapper">
        <Dropdown>
            <template slot="toggler">
                <VButton class="toggle-practices-list-button" name="Dext Account Switcher">
                    {{ currentTeam.name }}
                    <template #icon-right>
                        <VIcon
                            color="lightgray"
                            decorative
                            :name="'dext-arrow-down-filled'"
                            :size="20"
                            :vertical-align="false"
                        />
                    </template>
                </VButton>
            </template>

            <DropdownContent>
                <DropdownItem>
                    <div class="separator">Practices</div>
                </DropdownItem>
                <DropdownItem v-for="membership in practiceMemberships" :key="membership.crn">
                    <div
                        v-if="currentTeam.rbExternalId !== membership.crn"
                        class="dropdown-item-value"
                        @click="switchTo(membership)"
                    >
                        <VAvatar
                            :avatar="generateAvatarForPractice(membership)"
                            :for-team="true"
                            :tooltip="membership.name"
                        />
                        <div class="dropdown-item-value-team-name">
                            {{ membership.name }}
                        </div>
                    </div>
                </DropdownItem>

                <DropdownItem v-if="businessMemberships.length">
                    <div class="separator">Other businesses</div>
                </DropdownItem>
                <DropdownItem v-for="membership in businessMemberships" :key="membership.crn">
                    <div class="dropdown-item-value" @click="goToMembership(membership)">
                        <VAvatar
                            :avatar="generateAvatarForMembership(membership)"
                            :for-team="true"
                            :tooltip="membership.name"
                        />
                        <div class="dropdown-item-value-team-name">
                            {{ membership.name }}
                        </div>
                    </div>
                </DropdownItem>
                <DropdownItem v-if="businessMemberships.length">
                    <div class="separator" />
                </DropdownItem>
            </DropdownContent>
        </Dropdown>
        <ConfirmationModal
            v-model="showConfirmation"
            :confirm-button-text="confirmButtonText"
            name="Practice changing"
            @cancel="cancelConfirmation"
            @confirm="confirmSwitch"
        >
            <template #title>
                <div class="d-flex justify-content-between">
                    <span>You are changing practice</span>
                    <VButton class="d-modal__close-button" icon @click="cancelConfirmation">
                        <VIcon color="lightgray" name="close-thick" :size="20" />
                    </VButton>
                </div>
            </template>
            <div v-html="messageHtml" />
        </ConfirmationModal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { useTeamContext } from '@/App/composables/useTeamContext';
import { ConfirmationModal } from '@/components/ConfirmationModal';
import { Dropdown, DropdownItem, DropdownContent } from '@/components/Dext/common/Dropdown';
import { useUUID } from '@/mixins/uuid';

/* eslint-disable sort-keys */
export default {
    name: 'DextAccountSwitcher',
    components: {
        ConfirmationModal,
        Dropdown,
        DropdownItem,
        DropdownContent,
    },
    data() {
        return {
            showConfirmation: false,
            goToUrl: null,
            confirmButtonText: null,
            enteringText: null,
        };
    },
    setup() {
        const { linkToPrepare } = useTeamContext('DextAccountSwitcher');

        return { linkToPrepare };
    },

    computed: {
        ...mapGetters({
            currentTeam: 'teams/currentTeam',
            teamList: 'teams/teamList',
            membershipList: 'teams/membershipList',
            byCRN: 'teams/byCRN',
        }),

        practiceMemberships() {
            return this.membershipList.filter((membership) => membership.kind === 'Practice');
        },

        businessMemberships() {
            return this.membershipList.filter((membership) => membership.kind === 'Business');
        },

        messageHtml() {
            return (
                "You're now leaving Dext Precision for " +
                "<span class='font-weight-bold'>" +
                this.currentTeam.name +
                '</span>' +
                this.enteringText
            );
        },
    },
    methods: {
        switchTo(membership) {
            if (membership.systems.indexOf('xavier') > -1) {
                return this.switchTeam(membership);
            }

            return this.goToMembership(membership);
        },
        switchTeam(practice) {
            const team = this.byCRN(practice.crn);

            this.enteringText =
                ' and entering Dext Precision for ' + "<span class='font-weight-bold'>" + team.name + '</span>';
            this.confirmButtonText = 'Confirm and go to ' + team.name;
            this.goToUrl = `/settings/teams/${team.id}/switch`;

            this.showConfirmation = true;
        },
        goToMembership(membership) {
            this.enteringText =
                ' and entering Dext Prepare for ' + "<span class='font-weight-bold'>" + membership.name + '</span>';
            this.confirmButtonText = 'Confirm and go to ' + membership.name;
            this.goToUrl = this.linkToPrepare('clients', membership.crn);

            this.showConfirmation = true;
        },
        cancelConfirmation() {
            this.reset();
            this.showConfirmation = false;
        },
        confirmSwitch() {
            window.location.href = this.goToUrl;

            this.reset();
            this.showConfirmation = false;
        },
        generateUUID() {
            return useUUID();
        },
        generateAvatarForPractice(practice) {
            return (
                'https://ui-avatars.com/api/?background=E9F1FB&color=4991E9&length=1&size=32&&bold=true&name=' +
                practice.name
            );
        },
        generateAvatarForMembership(membership) {
            return (
                'https://ui-avatars.com/api/?background=E9F1FB&color=ff5900&length=1&size=32&&bold=true&name=' +
                membership.name
            );
        },
        reset() {
            this.goToUrl = null;
            this.confirmButtonText = null;
            this.enteringText = null;
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.dext-account-switcher-wrapper {
    font-family: $typeface-roboto;
}

.toggle-practices-list-button {
    background: get-color(silver, medium) !important;
    border-radius: pxtoem(16) !important;
    color: get-color(charcoal, lite) !important;
    font-size: pxtoem(16) !important;
    font-weight: $font-weight-bold !important;
    padding: pxtoem(2) pxtoem(16) !important;
}

.dropdown-item-value {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: pxtoem(13);
    font-weight: $font-weight-regular;
    padding: pxtoem(6) pxtoem(16);
    width: 100%;

    &:hover {
        background: get-color(orange, lite);
    }

    .dropdown-item-value-team-name {
        margin-left: pxtoem(8);
    }
}

.dext-dropdown-content {
    max-height: 80vh;
    overflow-y: auto;
}

.separator {
    color: get-color(gray, medium);
    font-size: pxtoem(14);
    font-weight: $font-weight-bold;
    letter-spacing: 0.01em;
    padding: pxtoem(14) pxtoem(16) pxtoem(6) pxtoem(16);
    width: 100%;
}
</style>
