import VToaster from 'v-toaster';

import { IS_EMBEDDED } from '@/config/env';

import type { PluginObject } from 'vue';

function postMessage(options: { type: 'error' | 'success' | 'info' | 'warning'; body: string }) {
    window.parent.postMessage(
        {
            action: 'SHOW_TOASTER',
            payload: {
                body: options.body,
                title: options.type.charAt(0).toUpperCase() + options.type.slice(1),
                type: options.type,
            },
        },
        '*'
    );
}

const VToasterInterceptor: PluginObject<undefined> = {
    install: (Vue) => {
        if (IS_EMBEDDED) {
            Vue.prototype.$toaster = {
                error: (body: string) => postMessage({ type: 'error', body }),
                info: (body: string) => postMessage({ type: 'info', body }),
                success: (body: string) => postMessage({ type: 'success', body }),
                warning: (body: string) => postMessage({ type: 'warning', body }),
            };

            return;
        }

        Vue.use(VToaster, { timeout: 8000 });
    },
};

export { VToasterInterceptor };
