import type { RouteConfig } from 'vue-router';

const practiceDashboardsRoutes: RouteConfig[] = [
    {
        component: { template: '<router-view />' },
        meta: { disableLegacyStyles: true },
        path: 'practice-dashboards',

        children: [
            {
                component: () => import('@/scenes/PracticeDashboards/Practice').then((m) => m.PracticeSceneContainer),
                meta: { disableLegacyStyles: true },
                name: 'team.practice-dashboards.practice',
                path: 'practice',
            },
            {
                component: () =>
                    import('@/scenes/PracticeDashboards/ActivityStats').then((m) => m.ActivityStatsSceneContainer),
                meta: { disableLegacyStyles: true },
                name: 'team.practice-dashboards.activity-stats',
                path: 'activity-stats',
            },
            {
                component: () => import('@/scenes/PracticeDashboards/Deadlines').then((m) => m.DeadlinesSceneContainer),
                meta: { disableLegacyStyles: true },
                name: 'team.practice-dashboards.deadlines',
                path: 'deadlines',
            },
            {
                component: () => import('@/scenes/PracticeDashboards/Mtd').then((m) => m.MtdSceneContainer),
                meta: { disableLegacyStyles: true },
                name: 'team.practice-dashboards.mtd',
                path: 'mtd',
            },
            {
                component: () => import('@/scenes/PracticeDashboards/Workflows').then((m) => m.WorkflowsSceneContainer),
                meta: { disableLegacyStyles: true },
                name: 'team.practice-dashboards.workflows',
                path: 'workflows',
            },
            {
                component: () =>
                    import('@/scenes/PracticeDashboards/CustomDashboards').then(
                        (m) => m.CustomDashboardsSceneContainer
                    ),
                meta: { disableLegacyStyles: true },
                name: 'team.practice-dashboards.custom-dashboards',
                path: 'custom-dashboards',
            },
        ],
    },
];

export { practiceDashboardsRoutes };
