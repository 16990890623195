import type { RouteConfig } from 'vue-router';

const customReportRoutes: RouteConfig[] = [
    {
        component: () => import('@/scenes/PracticeDashboard/PracticeDashboardSceneContainer.vue'),
        name: 'team.customReport.dashboard.details',
        path: 'custom-report/dashboard/:reportId',
        props: true,
    },
    {
        component: () => import('@/scenes/PracticeActivityStats/PracticeActivityStatsSceneContainer.vue'),
        name: 'team.customReport.activity.details',
        path: 'custom-report/activity/:reportId',
        props: true,
    },
    {
        component: () => import('@/scenes/MtdDashboard/MtdDashboardSceneContainer.vue'),
        name: 'team.customReport.mtd.details',
        path: 'custom-report/mtd/:reportId',
        props: true,
    },
    {
        component: () => import('@/scenes/WorkflowsDashboard/WorkflowsDashboardSceneContainer.vue'),
        name: 'team.customReport.focus.details',
        path: 'custom-report/focus/:reportId',
        props: true,
    },
    {
        component: () => import('@/scenes/DeadlinesDashboard/DeadlinesDashboardSceneContainer.vue'),
        name: 'team.customReport.deadlines.details',
        path: 'custom-report/deadlines/:reportId',
        props: true,
    },
];

export { customReportRoutes };
