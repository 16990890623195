import type { RouteConfig } from 'vue-router';

import { extractClientIdParam } from '../utils';

const cleanupRoutes: RouteConfig[] = [
    {
        component: () => import('@/scenes/BankReconciliation_LEGACY/BankReconciliationScene.vue'),
        name: 'client.cleanup.bankReconciliation',
        path: 'cleanup/bank-reconciliation',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/UnreconciledTransactions_LEGACY/UnreconciledTransactionsScene.vue'),
        name: 'client.cleanup.unreconciledTransactions',
        path: 'cleanup/unreconciled-transactions',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/DuplicateContacts_LEGACY/DuplicateContactsScene.vue'),
        name: 'client.cleanup.duplicateContacts',
        path: 'cleanup/duplicate-contacts',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/MultiCodedContacts/MultiCodedContactsScene.vue'),
        name: 'client.cleanup.multi-codedContacts',
        path: 'cleanup/multi-coded-contacts',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/ContactDefaults_LEGACY/ContactDefaultsScene.vue'),
        name: 'client.cleanup.contactDefaults',
        path: 'cleanup/contact-defaults',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/DormantAccounts_LEGACY/DormantAccountsScene.vue'),
        name: 'client.cleanup.dormantAccounts',
        path: 'cleanup/dormant-accounts',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/DuplicateTransactions/DuplicateTransactionsScene.vue'),
        name: 'client.cleanup.duplicateTransactions',
        path: 'cleanup/duplicate-transactions',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/TransactionCleanup_LEGACY/TransactionCleanupScene.vue'),
        name: 'client.cleanup.transactionCleanup',
        path: 'cleanup/transaction-cleanup',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/AbnValidation_LEGACY/AbnValidationScene.vue'),
        name: 'client.cleanup.abnValidation',
        path: 'cleanup/abn-validation',
        props: extractClientIdParam,
    },
];

export { cleanupRoutes };
