import { Feature } from '@/enums/Feature';
import ClientLayout, { ClientSidebar } from '@/layout/ClientLayout';
import { dataHealthRoutes } from '@/router/client/data-health.routes';
import { taxRoutes } from '@/router/client/tax.routes';
import store from '@/store';
import clientStore, { clientInitialState } from '@/store/modules/client/client.store';
import useIsFeatureEnabled from '@/store/modules/feature/hooks/useIsFeatureEnabled';

import type { ClientState } from '@/store/modules/client/types/Store';
import type { RouteConfig } from 'vue-router';

import { checkRoutes } from './check';
import { cleanupRoutes } from './cleanup';
import { customReportRoutes } from './custom-report';
import { flowRoutes } from './flow';
import { focusRoutes } from './focus';
import { insightRoutes } from './insight';
import { insightsRoutes } from './insights.routes';
import { resilienceRoutes } from './resilience';
import { settingsRoutes } from './settings';
import { workflowsRoutes } from './workflows.routes';

import { extractClientIdParam } from '../utils';

const hasCustomHealthScoreEnabled = useIsFeatureEnabled(Feature.CUSTOM_HEALTH_SCORES);

const clientRoutes: RouteConfig[] = [
    {
        component: ClientLayout,
        path: 'clients/:clientSlug',
        props: extractClientIdParam,

        beforeEnter(to, _from, next) {
            const { clientSlug } = to.params;
            const moduleName = `client_${clientSlug}`;
            const moduleExists = Boolean(store.state.clients[`client_${clientSlug}`]);
            const { clientId } = extractClientIdParam(to);

            if (!moduleExists) {
                store.registerModule(['clients', moduleName], {
                    ...clientStore,
                    state(): ClientState {
                        return {
                            ...clientInitialState,
                            data: { id: clientId, slug: clientSlug },
                        };
                    },
                });
            }

            next();
        },
        meta: {
            props: extractClientIdParam,
            sidebar: ClientSidebar,
            sidebarStacked: true,
        },

        children: [
            ...dataHealthRoutes,
            ...insightsRoutes,
            ...taxRoutes,
            ...checkRoutes,
            ...cleanupRoutes,
            ...customReportRoutes,
            ...flowRoutes,
            ...workflowsRoutes,
            ...focusRoutes,
            ...insightRoutes,
            ...resilienceRoutes,
            ...settingsRoutes,
            ...taxRoutes,
            {
                component: () =>
                    hasCustomHealthScoreEnabled
                        ? import('@/scenes/ClientOverview/ClientOverviewSceneContainer.vue')
                        : import('@/scenes/ClientOverview_LEGACY/ClientOverviewScene.vue'),
                name: 'client.overview',
                path: '',
                props: extractClientIdParam,
            },
            {
                component: () => import('@/scenes/ClientOverview_LEGACY/ClientOverviewScene.vue'),
                name: 'client.overview_legacy',
                path: 'legacy-overview',
                props: extractClientIdParam,
            },
            {
                component: () => import('@/scenes/ClientHealthScore/ClientHealthScoreSceneContainer.vue'),
                name: 'client.overview.health-score',
                path: 'health-score',
                props: extractClientIdParam,
            },
            {
                component: () => import('@/scenes/ClientBenchmarking/ClientBenchmarkingScene.vue'),
                name: 'client.benchmarking',
                path: 'benchmarking',
            },
            {
                component: () => import('@/scenes/ReportList/ReportListScene.vue'),
                name: 'client.reports',
                path: 'reports',
                props: extractClientIdParam,
            },
            {
                component: () => import('@/scenes/Sandbox_LEGACY/SandboxScene.vue'),
                name: 'client.sandbox',
                path: 'sandbox',
                props: extractClientIdParam,
            },
        ],
    },
];

export { clientRoutes };
