<template>
    <SidebarGroup name="Tax">
        <template #activator>Custom Reports</template>

        <template #icon-left>
            <VIcon decorative name="custom-reports-thick" />
        </template>

        <SidebarItem v-for="item in items" :key="item.name" :name="item.name" sub-item :to="item.to">
            {{ item.label }}
        </SidebarItem>
    </SidebarGroup>
</template>

<script>
import PropTypes from 'vue-types';

import { SidebarGroup, SidebarItem } from '@/layout/Sidebar';

import useBaseGroup from '../hooks/useBaseGroup';

export default {
    name: 'ClientSidebarGroupCustomReports',

    components: { SidebarGroup, SidebarItem },

    props: {
        client: PropTypes.object.isRequired,
        reportMode: PropTypes.string.isRequired,
    },

    setup(props) {
        const { createItems, isInsightSupported } = useBaseGroup(props.client, props.reportMode);

        return {
            createItems,

            isInsightSupported,
        };
    },

    computed: {
        items() {
            return this.createItems([
                {
                    label: 'Custom Reports',
                    name: 'Client Reports',
                    to: { name: 'client.reports', params: { clientSlug: this.client.slug } },
                },
                {
                    label: 'Report Creator',
                    name: 'Client Sandbox',
                    to: { name: 'client.sandbox', params: { clientSlug: this.client.slug } },
                },
            ]);
        },
    },
};
</script>
