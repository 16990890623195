import type { RouteConfig } from 'vue-router';

import { extractClientIdParam } from '../utils';

const workflowsRoutes: RouteConfig[] = [
    {
        component: { template: '<router-view />' },
        meta: { disableLegacyStyles: true },
        path: 'workflows',

        children: [
            {
                component: () =>
                    import('@/scenes/Workflows/WorkflowsList').then((m) => m.ClientWorkflowsListSceneContainer),
                meta: { disableLegacyStyles: true },
                name: 'client.workflows.list',
                path: '',
            },

            {
                component: () =>
                    import('@/scenes/Workflows/WorkflowDetails').then((m) => m.WorkflowDetailsSceneContainer),
                meta: { disableLegacyStyles: true },
                name: 'client.workflows.details',
                path: ':workflowSlug',
                props: extractClientIdParam,
            },
        ],
    },
];

export { workflowsRoutes };
