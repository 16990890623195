<template>
    <div class="system-bars" data-qa="page-layout-system-bars-header">
        <SystemBar v-if="$xavier.subscriptionError" type="warning">
            {{ $xavier.subscriptionError.message }}

            <template #actions>
                <VButton
                    v-if="Boolean($xavier.subscriptionError.intercom)"
                    color="primary"
                    name="SalesForce Subscription Error"
                    outline
                    small
                    @click="showChat"
                >
                    {{ $xavier.subscriptionError.intercom.message }}
                </VButton>
                <VButton
                    v-if="Boolean($xavier.subscriptionError.link)"
                    color="primary"
                    name="subscription-error-message"
                    outline
                    small
                >
                    <a :href="$xavier.subscriptionError.link.url">{{ $xavier.subscriptionError.link.message }}</a>
                </VButton>
            </template>
        </SystemBar>

        <SystemBar
            v-model="showDiscountBanner"
            dismissable
            type="success"
            @visibility-change="onDiscountBannerVisibilityChange"
        >
            COVID-19: 50% 3-month discount available

            <template #actions>
                <VLink
                    button
                    color="primary"
                    external
                    href="https://xavier-analytics.com/blog/2020/03/23/time-for-us-to-step-up-fifty-percent-discount/"
                    name="get-discount"
                    small
                >
                    Get the discount
                </VLink>
            </template>
        </SystemBar>

        <SystemBar v-if="showTrialBanner" :type="trialEndDateDiff < 10 ? 'warning' : 'info'">
            Your free trial ends in {{ trialEndDateDiff }} {{ $plural('day', trialEndDateDiff) }}

            <template v-if="canManageSubscription && !this.currentTeam.irisBilling && $xavier.isTeamOwner" #actions>
                <VButton
                    v-if="dextSelfServiceEnabled && currentTeam.isDext"
                    color="primary"
                    :href="linkToPrepare('subscription')"
                    link
                    name="subscribe"
                    outline
                    small
                >
                    Buy a plan
                </VButton>

                <VButton v-else color="primary" link name="subscribe" outline small :to="{ name: 'team.subscription' }">
                    Buy a plan
                </VButton>
            </template>
        </SystemBar>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { defineComponent } from 'vue';

import { useTeamContext } from '@/App/composables/useTeamContext';
import SystemBar from '@/components/SystemBar';
import { useContactSupport } from '@/composables/useContactSupport';
import { Feature } from '@/enums/Feature';
import UserPermissions from '@/enums/UserPermissions';
import useXavierGlobals from '@/hooks/useXavierGlobals';
import useIsFeatureEnabled from '@/store/modules/feature/hooks/useIsFeatureEnabled';
import { hasPermission } from '@/utils/permissions';

export default defineComponent({
    name: 'SystemBarsHeader',

    components: {
        SystemBar,
    },

    props: {
        currentTeam: {
            default: () => ({}),
            required: true,
            type: Object,
        },
    },

    data() {
        return {
            showDiscountBanner: false,
        };
    },

    setup() {
        const { showChat } = useContactSupport();
        const { urls } = useXavierGlobals();
        const dextSelfServiceEnabled = useIsFeatureEnabled(Feature.DEXT_SELF_SERVICE);
        const { linkToPrepare } = useTeamContext('SystemBarsHeader');

        return { dextSelfServiceEnabled, linkToPrepare, showChat, urls };
    },

    computed: {
        canManageSubscription() {
            return hasPermission(UserPermissions.MANAGE_SUBSCRIPTION);
        },
        showTrialBanner() {
            const hasExpiration = Boolean(this.currentTeam.trialExpires);
            const isTeamVip = this.currentTeam.isVIP;

            return hasExpiration && !isTeamVip;
        },

        trialEndDateDiff() {
            if (this.showTrialBanner) {
                const diff = moment(this.currentTeam.trialExpires).diff(moment(), 'days') + 1;

                return diff;
            }

            return null;
        },
    },

    watch: {
        currentTeam(value) {
            if (value) {
                this.showDiscountBanner = value.showDiscountPrompt === 1;
            }
        },
    },

    methods: {
        onDiscountBannerVisibilityChange(value) {
            if (!value) {
                axios.get('/discount/dismiss').then(function () {
                    window.Bus.$emit('updateCurrentTeam');
                });
            }
        },

        onSubscriptionErrorClick() {
            this.setPrechatData({ subject: 'some text' });
        },
    },
});
</script>

<style lang="scss" scoped>
.system-bars {
    width: 100%;
}
</style>
